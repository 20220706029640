.ui-background { background-image: url('./ui.png'); background-position: -2.5px -2.5px; width: 1920px; height: 1080px; }
.ui-focus { background-image: url('./ui.png'); background-position: -1927.5px -2.5px; width: 1920px; height: 1080px; }
.ui-blank_pick_landplot { background-image: url('./ui.png'); background-position: -2.5px -1087.5px; width: 1920px; height: 493px; }
.ui-blank_refill { background-image: url('./ui.png'); background-position: -2.5px -1585.5px; width: 1127px; height: 795px; }
.ui-popup_select_chain { background-image: url('./ui.png'); background-position: -2.5px -2385.5px; width: 800px; height: 800px; }
.ui-blank_fusion_list { background-image: url('./ui.png'); background-position: -1134.5px -1585.5px; width: 775px; height: 763px; }
.ui-blank_fusion { background-image: url('./ui.png'); background-position: -1914.5px -1585.5px; width: 775px; height: 763px; }
.ui-popup-leaderboard { background-image: url('./ui.png'); background-position: -2694.5px -1585.5px; width: 693px; height: 700px; }
.ui-popup_import { background-image: url('./ui.png'); background-position: -807.5px -2385.5px; width: 800px; height: 587px; }
.ui-blank_world_tree_2 { background-image: url('./ui.png'); background-position: -2.5px -3190.5px; width: 631px; height: 622px; }
.ui-popup_confirm_fusion { background-image: url('./ui.png'); background-position: -1612.5px -2385.5px; width: 693px; height: 509px; }
.ui-blank_mutate_stats_2 { background-image: url('./ui.png'); background-position: -1927.5px -1087.5px; width: 714px; height: 400px; }
.ui-added_worldtree_1 { background-image: url('./ui.png'); background-position: -2646.5px -1087.5px; width: 366px; height: 366px; }
.ui-border_hl_landplot { background-image: url('./ui.png'); background-position: -3017.5px -1087.5px; width: 461px; height: 266px; }
.ui-blank_landplot { background-image: url('./ui.png'); background-position: -3392.5px -1585.5px; width: 455px; height: 260px; }
.ui-border_hl_landplot_2 { background-image: url('./ui.png'); background-position: -3392.5px -1850.5px; width: 428px; height: 249px; }
.ui-blank_landplot_2 { background-image: url('./ui.png'); background-position: -2310.5px -2385.5px; width: 418px; height: 239px; }
.ui-added_worldtree_2 { background-image: url('./ui.png'); background-position: -638.5px -3190.5px; width: 315px; height: 315px; }
.ui-blank_mutation_slot { background-image: url('./ui.png'); background-position: -958.5px -3190.5px; width: 300px; height: 300px; }
.ui-mutategenHD { background-image: url('./ui.png'); background-position: -3483.5px -1087.5px; width: 293px; height: 264px; }
.ui-button_start_mutate_grey { background-image: url('./ui.png'); background-position: -2733.5px -2385.5px; width: 503px; height: 146px; }
.ui-button_start_mutate_pressed { background-image: url('./ui.png'); background-position: -3241.5px -2385.5px; width: 503px; height: 146px; }
.ui-loading { background-image: url('./ui.png'); background-position: -1263.5px -3190.5px; width: 266px; height: 272px; }
.ui-blank_mutate_stats { background-image: url('./ui.png'); background-position: -3017.5px -1358.5px; width: 758px; height: 75px; }
.ui-blank_fusion_mongen { background-image: url('./ui.png'); background-position: -2310.5px -2629.5px; width: 238px; height: 239px; }
.ui-border_hl_mongen { background-image: url('./ui.png'); background-position: -2553.5px -2629.5px; width: 238px; height: 239px; }
.ui-elixir_no_border { background-image: url('./ui.png'); background-position: -1534.5px -3190.5px; width: 207px; height: 249px; }
.ui-elixirHD { background-image: url('./ui.png'); background-position: -1746.5px -3190.5px; width: 207px; height: 249px; }
.ui-button_confirm_fusion_workshop_pressed { background-image: url('./ui.png'); background-position: -3392.5px -2104.5px; width: 351px; height: 142px; }
.ui-button_confirm_fusion_workshop { background-image: url('./ui.png'); background-position: -2796.5px -2629.5px; width: 351px; height: 142px; }
.ui-added_mutation { background-image: url('./ui.png'); background-position: -1958.5px -3190.5px; width: 215px; height: 215px; }
.ui-scroll_horiz_2 { background-image: url('./ui.png'); background-position: -1927.5px -1492.5px; width: 1920px; height: 18px; }
.ui-button_start_fusion { background-image: url('./ui.png'); background-position: -807.5px -2977.5px; width: 223px; height: 185px; }
.ui-blank_fusion_slot { background-image: url('./ui.png'); background-position: -2178.5px -3190.5px; width: 192px; height: 200px; }
.ui-button_save_worldtree { background-image: url('./ui.png'); background-position: -3152.5px -2629.5px; width: 310px; height: 118px; }
.ui-button_stack { background-image: url('./ui.png'); background-position: -3467.5px -2629.5px; width: 310px; height: 118px; }
.ui-blank_mutate_gen_plus { background-image: url('./ui.png'); background-position: -2796.5px -2776.5px; width: 387px; height: 90px; }
.ui-blank_elixir_plus { background-image: url('./ui.png'); background-position: -3188.5px -2776.5px; width: 374px; height: 90px; }
.ui-blank_elixir { background-image: url('./ui.png'); background-position: -2733.5px -2536.5px; width: 374px; height: 86px; }
.ui-blank_mutate_gen { background-image: url('./ui.png'); background-position: -3112.5px -2536.5px; width: 387px; height: 82px; }
.ui-button_treeworld_change { background-image: url('./ui.png'); background-position: -1035.5px -2977.5px; width: 257px; height: 118px; }
.ui-button_treeworld_Fusion { background-image: url('./ui.png'); background-position: -1297.5px -2977.5px; width: 257px; height: 118px; }
.ui-button_confirm_mutation_pressed { background-image: url('./ui.png'); background-position: -1559.5px -2977.5px; width: 288px; height: 99px; }
.ui-button_confirm_mutation { background-image: url('./ui.png'); background-position: -1852.5px -2977.5px; width: 288px; height: 99px; }
.ui-button_discard_mutation_pressed { background-image: url('./ui.png'); background-position: -2145.5px -2977.5px; width: 288px; height: 99px; }
.ui-button_discard_mutation { background-image: url('./ui.png'); background-position: -2438.5px -2977.5px; width: 288px; height: 99px; }
.ui-scroll_verti_2 { background-image: url('./ui.png'); background-position: -3852.5px -2.5px; width: 23px; height: 1080px; }
.ui-blank_MSTR_plus { background-image: url('./ui.png'); background-position: -2731.5px -2977.5px; width: 308px; height: 91px; }
.ui-blank_MSTR_2 { background-image: url('./ui.png'); background-position: -3044.5px -2977.5px; width: 308px; height: 89px; }
.ui-MSTR { background-image: url('./ui.png'); background-position: -3357.5px -2977.5px; width: 308px; height: 89px; }
.ui-fusion_workshop_blank { background-image: url('./ui.png'); background-position: -1927.5px -1515.5px; width: 496px; height: 53px; }
.ui-blank_MSTR { background-image: url('./ui.png'); background-position: -2375.5px -3190.5px; width: 302px; height: 89px; }
.ui-blank_slot_limit { background-image: url('./ui.png'); background-position: -638.5px -3510.5px; width: 247px; height: 109px; }
.ui-blank_MAG { background-image: url('./ui.png'); background-position: -2375.5px -3284.5px; width: 292px; height: 90px; }
.ui-fushion_workshop_bar_edited { background-image: url('./ui.png'); background-position: -2428.5px -1515.5px; width: 488px; height: 45px; }
.ui-button_confirm_fusion_pressed { background-image: url('./ui.png'); background-position: -890.5px -3510.5px; width: 232px; height: 99px; }
.ui-button_confirm_fusion { background-image: url('./ui.png'); background-position: -1127.5px -3510.5px; width: 232px; height: 99px; }
.ui-button_discard_fusion_pressed { background-image: url('./ui.png'); background-position: -1364.5px -3510.5px; width: 232px; height: 99px; }
.ui-button_discard_fusion { background-image: url('./ui.png'); background-position: -1601.5px -3510.5px; width: 232px; height: 99px; }
.ui-button_inventory { background-image: url('./ui.png'); background-position: -638.5px -3624.5px; width: 151px; height: 153px; }
.ui-button_mutation { background-image: url('./ui.png'); background-position: -794.5px -3624.5px; width: 151px; height: 150px; }
.ui-button_setup_land { background-image: url('./ui.png'); background-position: -950.5px -3624.5px; width: 151px; height: 150px; }
.ui-blank_mutate_gen_2 { background-image: url('./ui.png'); background-position: -1612.5px -2899.5px; width: 348px; height: 62px; }
.ui-button_fusion { background-image: url('./ui.png'); background-position: -3880.5px -2.5px; width: 135px; height: 163px; }
.ui-button_mongen_on { background-image: url('./ui.png'); background-position: -3567.5px -2776.5px; width: 232px; height: 89px; }
.ui-blank_mainmenu_1 { background-image: url('./ui.png'); background-position: -2694.5px -2290.5px; width: 375px; height: 53px; }
.ui-blank_mainmenu_2 { background-image: url('./ui.png'); background-position: -3074.5px -2290.5px; width: 375px; height: 53px; }
.ui-blank_pool { background-image: url('./ui.png'); background-position: -3454.5px -2290.5px; width: 375px; height: 53px; }
.ui-button_refill_avai { background-image: url('./ui.png'); background-position: -1838.5px -3510.5px; width: 210px; height: 95px; }
.ui-button_refill_unavai { background-image: url('./ui.png'); background-position: -2053.5px -3510.5px; width: 210px; height: 95px; }
.ui-blank_mainmenu_storage { background-image: url('./ui.png'); background-position: -3504.5px -2536.5px; width: 301px; height: 62px; }
.ui-blank_storage { background-image: url('./ui.png'); background-position: -1965.5px -2899.5px; width: 301px; height: 62px; }
.ui-Mint_Value { background-image: url('./ui.png'); background-position: -2271.5px -2899.5px; width: 345px; height: 53px; }
.ui-fushion_workshop_bar { background-image: url('./ui.png'); background-position: -2921.5px -1515.5px; width: 376px; height: 45px; }
.ui-blank_elixir_2 { background-image: url('./ui.png'); background-position: -1035.5px -3100.5px; width: 264px; height: 62px; }
.ui-added_fusion { background-image: url('./ui.png'); background-position: -3880.5px -170.5px; width: 119px; height: 119px; }
.ui-button_back_on { background-image: url('./ui.png'); background-position: -3670.5px -2977.5px; width: 167px; height: 84px; }
.ui-button_back_pressed { background-image: url('./ui.png'); background-position: -2682.5px -3190.5px; width: 167px; height: 84px; }
.ui-button_cancel_on { background-image: url('./ui.png'); background-position: -2854.5px -3190.5px; width: 179px; height: 72px; }
.ui-button_cancel_oressed { background-image: url('./ui.png'); background-position: -3038.5px -3190.5px; width: 179px; height: 72px; }
.ui-button_save_cancel_grey { background-image: url('./ui.png'); background-position: -3222.5px -3190.5px; width: 179px; height: 72px; }
.ui-button_save_on { background-image: url('./ui.png'); background-position: -3406.5px -3190.5px; width: 179px; height: 72px; }
.ui-blank_filter { background-image: url('./ui.png'); background-position: -3302.5px -1515.5px; width: 264px; height: 44px; }
.ui-checkbox { background-image: url('./ui.png'); background-position: -3880.5px -294.5px; width: 119px; height: 99px; }
.ui-scroll_verti { background-image: url('./ui.png'); background-position: -3880.5px -398.5px; width: 15px; height: 543px; }
.ui-mutategen { background-image: url('./ui.png'); background-position: -3900.5px -398.5px; width: 92px; height: 82px; }
.ui-scroll_horiz { background-image: url('./ui.png'); background-position: -3017.5px -1438.5px; width: 401px; height: 14px; }
.ui-elixir  { background-image: url('./ui.png'); background-position: -3900.5px -485.5px; width: 79px; height: 86px; }
.ui-blank_filter_2 { background-image: url('./ui.png'); background-position: -3571.5px -1515.5px; width: 143px; height: 44px; }
.ui-button_all_grey { background-image: url('./ui.png'); background-position: -3900.5px -576.5px; width: 111px; height: 48px; }
.ui-button_all_on { background-image: url('./ui.png'); background-position: -3900.5px -629.5px; width: 111px; height: 48px; }
.ui-button_all_pressed { background-image: url('./ui.png'); background-position: -3900.5px -682.5px; width: 111px; height: 48px; }
.ui-button_CO_on { background-image: url('./ui.png'); background-position: -3900.5px -735.5px; width: 111px; height: 48px; }
.ui-button_CO_presed { background-image: url('./ui.png'); background-position: -3900.5px -788.5px; width: 111px; height: 48px; }
.ui-button_EP_on { background-image: url('./ui.png'); background-position: -3900.5px -841.5px; width: 111px; height: 48px; }
.ui-button_EP_pressed { background-image: url('./ui.png'); background-position: -3880.5px -946.5px; width: 111px; height: 48px; }
.ui-button_LE_on { background-image: url('./ui.png'); background-position: -3880.5px -999.5px; width: 111px; height: 48px; }
.ui-button_LE_pressed { background-image: url('./ui.png'); background-position: -3880.5px -1052.5px; width: 111px; height: 48px; }
.ui-button_RA_on { background-image: url('./ui.png'); background-position: -3880.5px -1105.5px; width: 111px; height: 48px; }
.ui-button_RA_pressed { background-image: url('./ui.png'); background-position: -3880.5px -1158.5px; width: 111px; height: 48px; }
.ui-button_UC_on { background-image: url('./ui.png'); background-position: -3880.5px -1211.5px; width: 111px; height: 48px; }
.ui-button_UC_presed { background-image: url('./ui.png'); background-position: -3880.5px -1264.5px; width: 111px; height: 48px; }
.ui-button_UC_pressed { background-image: url('./ui.png'); background-position: -3880.5px -1317.5px; width: 111px; height: 48px; }
.ui-elixir_small { background-image: url('./ui.png'); background-position: -3880.5px -1370.5px; width: 48px; height: 48px; }
.ui-icon_mag { background-image: url('./ui.png'); background-position: -3933.5px -1370.5px; width: 48px; height: 48px; }
.ui-icon_mstr { background-image: url('./ui.png'); background-position: -3880.5px -1423.5px; width: 48px; height: 48px; }
.ui-arrow_left_off { background-image: url('./ui.png'); background-position: -3933.5px -1423.5px; width: 46px; height: 48px; }
.ui-arrow_left_on { background-image: url('./ui.png'); background-position: -3880.5px -1476.5px; width: 46px; height: 48px; }
.ui-arrow_right_off { background-image: url('./ui.png'); background-position: -3931.5px -1476.5px; width: 46px; height: 48px; }
.ui-arrow_right_on { background-image: url('./ui.png'); background-position: -3880.5px -1529.5px; width: 46px; height: 48px; }
.ui-button_filter_on { background-image: url('./ui.png'); background-position: -3900.5px -894.5px; width: 38px; height: 44px; }
.ui-button_filter_pressed { background-image: url('./ui.png'); background-position: -3943.5px -894.5px; width: 38px; height: 44px; }
.ui-icon_beast { background-image: url('./ui.png'); background-position: -3931.5px -1529.5px; width: 36px; height: 36px; }
.ui-icon_celest { background-image: url('./ui.png'); background-position: -3972.5px -1529.5px; width: 36px; height: 36px; }
.ui-icon_chaos { background-image: url('./ui.png'); background-position: -3880.5px -1582.5px; width: 36px; height: 36px; }
.ui-icon_mystic { background-image: url('./ui.png'); background-position: -3921.5px -1582.5px; width: 36px; height: 36px; }
.ui-icon_tectos { background-image: url('./ui.png'); background-position: -3962.5px -1582.5px; width: 36px; height: 36px; }
.ui-star_black { background-image: url('./ui.png'); background-position: -3982.5px -1476.5px; width: 32px; height: 32px; }
.ui-star { background-image: url('./ui.png'); background-position: -3880.5px -1623.5px; width: 32px; height: 32px; }
