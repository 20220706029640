.anim-luminous_2_1 { background-image: url('./anim.png'); background-position: -2.5px -2.5px; width: 573px; height: 343px; }
.anim-luminous_2_2 { background-image: url('./anim.png'); background-position: -580.5px -2.5px; width: 573px; height: 343px; }
.anim-luminous_2_3 { background-image: url('./anim.png'); background-position: -2.5px -350.5px; width: 573px; height: 343px; }
.anim-luminous_1_1 { background-image: url('./anim.png'); background-position: -580.5px -350.5px; width: 387px; height: 208px; }
.anim-luminous_1_2 { background-image: url('./anim.png'); background-position: -2.5px -698.5px; width: 387px; height: 208px; }
.anim-luminous_1_3 { background-image: url('./anim.png'); background-position: -394.5px -698.5px; width: 387px; height: 207px; }
.anim-foam1 { background-image: url('./anim.png'); background-position: -786.5px -698.5px; width: 279px; height: 172px; }
.anim-foam2 { background-image: url('./anim.png'); background-position: -2.5px -911.5px; width: 279px; height: 172px; }
.anim-foam3 { background-image: url('./anim.png'); background-position: -286.5px -911.5px; width: 279px; height: 172px; }
.anim-waterfall1 { background-image: url('./anim.png'); background-position: -972.5px -350.5px; width: 167px; height: 205px; }
.anim-waterfall2 { background-image: url('./anim.png'); background-position: -1158.5px -2.5px; width: 167px; height: 205px; }
.anim-waterfall3 { background-image: url('./anim.png'); background-position: -1158.5px -212.5px; width: 167px; height: 205px; }
.anim-glowing_light { background-image: url('./anim.png'); background-position: -1158.5px -422.5px; width: 162px; height: 119px; }
.anim-smoke { background-image: url('./anim.png'); background-position: -1158.5px -546.5px; width: 160px; height: 111px; }
.anim-smoke2 { background-image: url('./anim.png'); background-position: -1158.5px -662.5px; width: 160px; height: 111px; }
.anim-smoke3 { background-image: url('./anim.png'); background-position: -1158.5px -778.5px; width: 160px; height: 111px; }
.anim-smoke4 { background-image: url('./anim.png'); background-position: -1158.5px -894.5px; width: 160px; height: 111px; }
.anim-smoke5 { background-image: url('./anim.png'); background-position: -580.5px -563.5px; width: 160px; height: 111px; }
.anim-fire1 { background-image: url('./anim.png'); background-position: -1158.5px -1010.5px; width: 47px; height: 43px; }
.anim-fire2 { background-image: url('./anim.png'); background-position: -1210.5px -1010.5px; width: 47px; height: 43px; }
.anim-fire3 { background-image: url('./anim.png'); background-position: -1262.5px -1010.5px; width: 47px; height: 43px; }
