@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Rimouski";
  src: local("Rimouski"), url("assets/font/rimouski sb.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "CookieRun";
  src: local("CookieRun"), url("assets/font/cr_bold.otf") format("truetype");
  font-weight: normal;
}

.font-semantic {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.font-cookie-run {
  font-family: "CookieRun";
}

html,
body {
  font-family: Rimouski;
  font-size: 22px;
}

.interactive {
  cursor: url("./assets/cursor/cursor-hover.png"), auto;
  /* transition-duration: 150ms; */
}

.interactive:hover {
  filter: brightness(1.2);
}

.interactive:focus {
  filter: brightness(1);
}

.spine-player-canvas {
  height: 500px;
  width: 500px;
}

::-webkit-scrollbar {
  border-radius: 10px !important;
  width: 20px !important;
  min-height: 20px !important;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background: #6d6152 !important;
}

::-webkit-scrollbar-thumb {
  background: #ffffff !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

body {
  overflow: hidden;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.rotate {
  animation: rotation 1s infinite linear;
}

.button-logo-container {
  width: 90px;
}

.button-logo-container div {
  color: #8690b1;
  text-align: center;
  word-wrap: break-word;
  font-size: 12px;
  font-weight: bold;
}

p.text-outline {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  text-shadow: #000 0px 0px 4px, #000 0px 0px 4px, #000 0px 0px 4px,
    #000 0px 0px 4px, #000 0px 0px 4px, #000 0px 0px 4px !important;
  -webkit-font-smoothing: antialiased;
}

.bg {
  background-image: url("/public/assets/images/bg.png");
  background-size: 100% 100%;
}

.button-icon {
  transition: all 0.3s;
  background-image: url("/public/assets/images/button.png");
  background-size: 100% 100%;
  background-color: transparent !important;
}

.button-logo {
  width: 90px;
  height: 68px;
  cursor: pointer;
  border: 1px solid black;
  justify-content: center;
  align-self: center;
  display: flex;
  border-radius: 12px;
}

.button-logo img {
  width: 52px;
  height: 52px;
  margin: auto;
}

.button-logo {
  transition: all 0.3s;
  background-image: url("/public/assets/images/button-bg.png");
  background-size: 100% 100%;
  background-position: 0% 4%;
  background-color: transparent !important;
}

.br {
  background-color: #383e65;
  height: 4px;
  width: 60%;
  border-radius: 10px;
  text-align: center;
  justify-content: center;
  margin: auto;
}

.Toastify__toast {
  background-color: #505780;
  color: white;
}

.Toastify__close-button > svg {
  color: white;
}

.rc-slider-handle {
  cursor: pointer !important;
  transform: translateX(0%) translateY(2px) !important;
  width: 26px !important;
  height: 26px !important;
  z-index: 9;
}

.rc-slider,
.rc-slider-horizontal {
  transform: translateY(7px);
}

.rc-slider-rail {
  height: 20px !important;
  border-radius: 0 !important;
  z-index: 9;
  margin-top: 0.4px;
  transform: translateX(18px);
}

.captcha-slider .rc-slider-rail {
  height: 20px !important;
  border-radius: 0 !important;
  z-index: 9;
  margin-top: 0.4px;
  transform: translateX(0px) !important;
  display: flex;
  text-align: center;
  justify-self: center;
}

.captcha-slider .rc-slider-rail::after {
  content: "Drag the slider to fit the puzzle piece";
  font-size: 13px !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
}

.rc-slider-track {
  height: 20px !important;
  border-radius: 0 !important;
  z-index: 9;
}

.rc-slider-handle {
  border-radius: 12% !important;
  opacity: 1 !important;
}

.rc-slider-handle::after {
  content: "| |";
  font-size: 13px !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
}

.rc-slider-handle:hover,
.rc-slider-handle:focus {
  border-width: 1px;
  border-color: #ebc276 !important;
  box-shadow: unset !important;
}

.rc-slider-disabled {
  padding: unset !important;
}

.custom-dropdown i {
  opacity: 0;
}

.text-component {
  font-size: 18px;
  font-weight: bold;
  /* -webkit-text-stroke: 0.5px #fdb109; */
  text-shadow: 2px 2px #000000cf;
}

.text-component:hover {
  color: #fdb109;
}

.text-component:focus {
  color: #fdb109;
}

.bg-rarity-0 {
  background: #BDC3E3;
}

.bg-rarity-1 {
  background: #4DA74F;
}

.bg-rarity-2 {
  background: #6A47D2;
}

.bg-rarity-3 {
  background: #A260C0;
}

.bg-rarity-4 {
  background: #FF6224;
}
