particle {
  display: block;
  width: 200px;
  height: 200px;
  pointer-events: none;
  position: absolute;
  left: 50%;
  bottom: 50%;
  transform-origin: center bottom;
  transition: transform 2s, opacity 1s;
  transition-timing-function: ease-out;
  background-image: url('./assets/particle.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
}