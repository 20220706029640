.map-ocean { background-image: url('./map.png'); background-position: -2.5px -2.5px; width: 1920px; height: 1080px; }
.map-ocean_foam { background-image: url('./map.png'); background-position: -1927.5px -2.5px; width: 1669px; height: 1053px; }
.map-drop_shadow { background-image: url('./map.png'); background-position: -2.5px -1087.5px; width: 1313px; height: 914px; }
.map-land { background-image: url('./map.png'); background-position: -2.5px -2006.5px; width: 1270px; height: 922px; }
.map-island_habitat_CO { background-image: url('./map.png'); background-position: -1320.5px -1087.5px; width: 658px; height: 666px; }
.map-island_habitat_EP { background-image: url('./map.png'); background-position: -1983.5px -1087.5px; width: 658px; height: 666px; }
.map-island_habitat_LE { background-image: url('./map.png'); background-position: -2646.5px -1087.5px; width: 658px; height: 666px; }
.map-island_habitat_RA { background-image: url('./map.png'); background-position: -1277.5px -2006.5px; width: 658px; height: 666px; }
.map-island_habitat_UC { background-image: url('./map.png'); background-position: -1940.5px -2006.5px; width: 658px; height: 666px; }
.map-island_workshop_LE_3 { background-image: url('./map.png'); background-position: -2603.5px -2006.5px; width: 607px; height: 474px; }
.map-flying_island { background-image: url('./map.png'); background-position: -2.5px -2933.5px; width: 588px; height: 478px; }
.map-island_workshop_EP_3 { background-image: url('./map.png'); background-position: -595.5px -2933.5px; width: 607px; height: 435px; }
.map-island_workshop_CO_3 { background-image: url('./map.png'); background-position: -1207.5px -2933.5px; width: 607px; height: 410px; }
.map-island_workshop_LE_2 { background-image: url('./map.png'); background-position: -3601.5px -2.5px; width: 491px; height: 495px; }
.map-island_workshop_RA_3 { background-image: url('./map.png'); background-position: -1819.5px -2933.5px; width: 607px; height: 397px; }
.map-island_workshop_UC_3 { background-image: url('./map.png'); background-position: -2431.5px -2933.5px; width: 607px; height: 397px; }
.map-island_workshop_EP_2 { background-image: url('./map.png'); background-position: -3601.5px -502.5px; width: 481px; height: 429px; }
.map-luminous_2 { background-image: url('./map.png'); background-position: -2.5px -3416.5px; width: 570px; height: 333px; }
.map-island_workshop_RA_2 { background-image: url('./map.png'); background-position: -3601.5px -936.5px; width: 431px; height: 410px; }
.map-island_workshop_CO_2 { background-image: url('./map.png'); background-position: -3601.5px -1351.5px; width: 431px; height: 400px; }
.map-island_workshop_UC_2 { background-image: url('./map.png'); background-position: -3601.5px -1756.5px; width: 431px; height: 400px; }
.map-island_workshop_LE_1 { background-image: url('./map.png'); background-position: -3601.5px -2161.5px; width: 372px; height: 400px; }
.map-island_workshop_EP_1 { background-image: url('./map.png'); background-position: -3601.5px -2566.5px; width: 362px; height: 334px; }
.map-island_workshop_RA_1 { background-image: url('./map.png'); background-position: -3601.5px -2905.5px; width: 310px; height: 314px; }
.map-island_workshop_UC_1 { background-image: url('./map.png'); background-position: -3215.5px -2006.5px; width: 310px; height: 298px; }
.map-luminous_1 { background-image: url('./map.png'); background-position: -1320.5px -1758.5px; width: 387px; height: 208px; }
.map-island_workshop_CO_1 { background-image: url('./map.png'); background-position: -1277.5px -2677.5px; width: 310px; height: 233px; }
.map-island_storage_LE { background-image: url('./map.png'); background-position: -3309.5px -1087.5px; width: 212px; height: 329px; }
.map-island_defense_CO_1 { background-image: url('./map.png'); background-position: -3309.5px -1421.5px; width: 229px; height: 298px; }
.map-island_defense_CO_2 { background-image: url('./map.png'); background-position: -3043.5px -2933.5px; width: 229px; height: 298px; }
.map-island_defense_CO_3 { background-image: url('./map.png'); background-position: -3277.5px -2933.5px; width: 229px; height: 298px; }
.map-island_defense_EP_1 { background-image: url('./map.png'); background-position: -577.5px -3416.5px; width: 229px; height: 298px; }
.map-island_defense_EP_2 { background-image: url('./map.png'); background-position: -811.5px -3416.5px; width: 229px; height: 298px; }
.map-island_defense_EP_3 { background-image: url('./map.png'); background-position: -1045.5px -3416.5px; width: 229px; height: 298px; }
.map-island_defense_LE_1 { background-image: url('./map.png'); background-position: -1279.5px -3416.5px; width: 229px; height: 298px; }
.map-island_defense_LE_2 { background-image: url('./map.png'); background-position: -1513.5px -3416.5px; width: 229px; height: 298px; }
.map-island_defense_LE_3 { background-image: url('./map.png'); background-position: -1747.5px -3416.5px; width: 229px; height: 298px; }
.map-island_defense_RA_1 { background-image: url('./map.png'); background-position: -1981.5px -3416.5px; width: 229px; height: 298px; }
.map-island_defense_RA_2 { background-image: url('./map.png'); background-position: -2215.5px -3416.5px; width: 229px; height: 298px; }
.map-island_defense_RA_3 { background-image: url('./map.png'); background-position: -2449.5px -3416.5px; width: 229px; height: 298px; }
.map-island_defense_UC_1 { background-image: url('./map.png'); background-position: -2683.5px -3416.5px; width: 229px; height: 298px; }
.map-island_defense_UC_2 { background-image: url('./map.png'); background-position: -2917.5px -3416.5px; width: 229px; height: 298px; }
.map-island_defense_UC_3 { background-image: url('./map.png'); background-position: -3151.5px -3416.5px; width: 229px; height: 298px; }
.map-island_landcore_LE { background-image: url('./map.png'); background-position: -3385.5px -3416.5px; width: 245px; height: 253px; }
.map-island_storage_EP { background-image: url('./map.png'); background-position: -2.5px -3754.5px; width: 212px; height: 288px; }
.map-animal  { background-image: url('./map.png'); background-position: -1592.5px -2677.5px; width: 241px; height: 213px; }
.map-animal_1 { background-image: url('./map.png'); background-position: -1838.5px -2677.5px; width: 241px; height: 213px; }
.map-animal_2 { background-image: url('./map.png'); background-position: -2084.5px -2677.5px; width: 241px; height: 213px; }
.map-animal_3 { background-image: url('./map.png'); background-position: -2330.5px -2677.5px; width: 241px; height: 213px; }
.map-island_storage_RA { background-image: url('./map.png'); background-position: -3635.5px -3416.5px; width: 187px; height: 228px; }
.map-island_landcore_EP { background-image: url('./map.png'); background-position: -3916.5px -2905.5px; width: 173px; height: 219px; }
.map-drop_shadow_fly_isl { background-image: url('./map.png'); background-position: -3601.5px -3224.5px; width: 350px; height: 90px; }
.map-island_landcore_RA { background-image: url('./map.png'); background-position: -3827.5px -3416.5px; width: 142px; height: 223px; }
.map-island_storage_UC { background-image: url('./map.png'); background-position: -1712.5px -1758.5px; width: 162px; height: 195px; }
.map-island_storage_CO { background-image: url('./map.png'); background-position: -1879.5px -1758.5px; width: 156px; height: 170px; }
.map-core_5 { background-image: url('./map.png'); background-position: -2040.5px -1758.5px; width: 142px; height: 142px; }
.map-core1_2 { background-image: url('./map.png'); background-position: -3215.5px -2309.5px; width: 131px; height: 151px; }
.map-island_landcore_UC { background-image: url('./map.png'); background-position: -3978.5px -2161.5px; width: 106px; height: 172px; }
.map-tree { background-image: url('./map.png'); background-position: -3978.5px -2338.5px; width: 111px; height: 153px; }
.map-core_4 { background-image: url('./map.png'); background-position: -3968.5px -2566.5px; width: 121px; height: 137px; }
.map-island_landcore_CO { background-image: url('./map.png'); background-position: -2187.5px -1758.5px; width: 132px; height: 121px; }
.map-core3 { background-image: url('./map.png'); background-position: -3968.5px -2708.5px; width: 115px; height: 115px; }
.map-token_full { background-image: url('./map.png'); background-position: -3916.5px -3129.5px; width: 96px; height: 89px; }
.map-token_half { background-image: url('./map.png'); background-position: -3978.5px -2496.5px; width: 88px; height: 52px; }
