@font-face {
  font-family: "CookieRunRegular";
  src: local("CookieRun"), url("/public/assets/fonts/CookieRun-Regular.otf") format("opentype");
}
.pagecomponent #menu-dropdown {
  margin-left: 12px;
  width: 10px !important;
  height: 6px !important;
}
.pagecomponent .page-component:hover {
  border-radius: 4px;
  background: #323134;
}
.pagecomponent .page-component:hover .text-header {
  color: #e89f01 !important;
}
.pagecomponent .page-component .text-header {
  font-family: "CookieRunRegular";
  font-weight: bold;
  color: #e1e2e9;
}
.pagecomponent .menu-icon:hover img {
  filter: invert(57%) sepia(29%) saturate(2462%) hue-rotate(12deg) brightness(103%) contrast(103%);
}
.pagecomponent .bg-black-primary {
  background: #1c1b1e;
}
.pagecomponent .text-gray {
  color: #6c6d76;
}
.pagecomponent .br {
  border: 1px solid #2e303e;
  width: 98%;
}
.ui.popup {
  max-width: 100vw !important;
  padding: unset;
  border: unset;
}
.ui.modal {
  background: transparent;
}
.pagecomponent button {
  background-image: unset !important;
}
.hidden-page {
  opacity: 0.2;
  pointer-events: none;
}
.pagecomponent .page-active {
  color: #e89f01 !important;
}
.pagecomponent .page-active .text-header {
  color: #e89f01 !important;
}
.ui.button:disabled, .ui.buttons .disabled.button, .ui.disabled.active.button, .ui.disabled.button, .ui.disabled.button:hover {
  opacity: 0.25 !important;
}
.text-coming-soon {
  color: #960606;
  opacity: 1 !important;
  font-weight: bold;
}
.text-coming-soon-social {
  color: #960606;
  opacity: 1 !important;
  font-weight: bold;
  transform: translateY(-32px);
}
.text-ready {
  color: #1cc25e;
  opacity: 1 !important;
  font-weight: bold;
}
.pagecomponent {
  width: 84px;
}
.header-menu {
  position: fixed;
  top: 4px;
  left: 10px;
  z-index: 99;
  display: flex;
}

.page-component-game {
  background-color: #17192a !important;
  border: 3px solid #383d65 !important;
  border-top-left-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  transform: translateY(-7px) !important;
}

.pagecomponentgame {
  margin-left: calc(98vw - 97px) !important;
}

.pagecomponentgame .menu-icon {
  transform: translateY(-1px) !important;
}

.pagecomponentgame .menu-icon:hover img {
  filter: unset !important;
}

.pagecomponentgame button:focus {
  border: unset !important;
}